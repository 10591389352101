import { TClientEditRequest, TClientsSearchRequest } from "src/@types/clients";
import Gateway from "../Gateway";

const route = "/v1/clients/search";
const routeEdit = "/v1/clients/:id/edit";
const routeOldAccount = "/v1/clients/get-old-account?personalId=:id";
const edit = async (clientId: string, data: TClientEditRequest) => Gateway({ url: routeEdit, method: "PUT", id: clientId, data });

const get = async (data: TClientsSearchRequest) => Gateway({
    url: route,
    filters: [
        {
            name: "clientId",
            value: data.clientId as any,
        },
        {
            name: "personalId",
            value: data.personalId as any,
        },
        {
            name: "email",
            value: data.email as any,
        },
        {
            name: "phone",
            value: data.phone as any,
        }
    ]
});

const getOldAccount = async (id: number) => Gateway({
    url: routeOldAccount,
    id
});


export default {
    get,
    edit,
    getOldAccount
};