import { Button, Flex, Select, Table } from '@radix-ui/themes';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { TBetTotals } from 'src/@types/bet';
import { IReportByBet } from 'src/@types/reports';
import Filter, { FTypes } from 'src/components/Filter';
import MainLayout from 'src/components/MainLayout';
import TablePaginated from 'src/components/TablePaginated';
import Reports from 'src/infraestructure/services/api/Reports';

const ReportByPlayerSportbook: React.FC = () => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<IReportByBet[]>([]);
    const [page, setPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [perPage, setPerPage] = useState<number | string>(25);
    const [filters, setFilteres] = useState<any>();
    const [betTotals, setBetTotals] = useState<TBetTotals>();
    const [GGR, setGGR] = useState<string>();

    const handleFilter = async (data: any) => {
        setGGR("");
        setLoading(true);
        setPerPage(25);
        try {
            const reportByBet = await Reports.getReportBet({
                clientId: data.clientId,
                page: 1,
                limit: 25,
                startDate: data.startDate,
                endDate: data.endDate,
                isCalcTime: data.isCalcTime
            });
    
            if (reportByBet && reportByBet.data) {
                setTotalItems(reportByBet.pagination.total_items);
                setBetTotals(reportByBet.betTotals);
                setFilteres({
                    clientId: data.clientId,
                    limit: 25,
                    startDate: data.startDate,
                    endDate: data.endDate
                });
    
                setList(reportByBet.data);
            }
    
            setLoading(false);
        } catch(err){
            setLoading(false);

        }
    }

    const backPage = async () => {
        let curPage = page - 1;
        const reportByBet = await Reports.getReportBet({
            clientId: filters.clientId,
            page: curPage,
            limit: perPage as number,
            startDate: filters.startDate,
            endDate: filters.endDate
        });

        if (reportByBet && reportByBet.data) {
            setTotalItems(reportByBet.pagination.total_items);
            setList(reportByBet.data);
            setPage(curPage);
        }
    }

    const nextPage = async () => {
        let curPage = page + 1;
        const reportByBet = await Reports.getReportBet({
            clientId: filters.clientId,
            page: curPage,
            limit: perPage as number,
            startDate: filters.startDate,
            endDate: filters.endDate
        });

        if (reportByBet && reportByBet.data) {
            setTotalItems(reportByBet.pagination.total_items);
            if (reportByBet.data.length > 0) {
                setList(reportByBet.data);
                setPage(curPage);
            } else {
                toast.error(t(`No results found.`));
            }
        }
    }

    const handleChangePerPage = async (e: any) => {
        setPerPage(e);
        if (e !== "todos") {
            let perPageNumber: number = parseInt(e);
            const reportByBet = await Reports.getReportBet({
                clientId: filters.clientId,
                page: 1,
                limit: perPageNumber as number,
                startDate: filters.startDate,
                endDate: filters.endDate
            });

            if (reportByBet && reportByBet.data) {
                setTotalItems(reportByBet.pagination.total_items);
                setBetTotals(reportByBet.betTotals);
                setFilteres({
                    clientId: filters.clientId,
                    limit: perPageNumber,
                    startDate: filters.startDate,
                    endDate: filters.endDate
                });
                setPage(1);

                setList(reportByBet.data);
            }
        } else {
            const reportByBet = await Reports.getReportBet({
                clientId: filters.clientId,
                page: 1,
                limit: totalItems as number,
                startDate: filters.startDate,
                endDate: filters.endDate
            });

            if (reportByBet && reportByBet.data) {
                setTotalItems(reportByBet.pagination.total_items);
                setBetTotals(reportByBet.betTotals);
                setFilteres({
                    clientId: filters.clientId,
                    limit: totalItems,
                    startDate: filters.startDate,
                    endDate: filters.endDate
                });
                setPage(1);
                setList(reportByBet.data);
            }
        }

    }

    useEffect(() => {
        if(perPage === "todos" && list.length === totalItems) {
            calc8lateGGR();
        }
    }, [perPage, list])

    const calc8lateGGR = () => {
        let sumWin = 0;
        let sumLost = 0;
        
        list.map((i, index) => {
            if(i.StateName === "Ganhou") {
                sumWin += i.WinningAmount;
            }
        })

        console.log(sumWin);

        list.map((i, index) => {
            if(i.StateName === "Perdido") {
                sumLost += i.Amount;
            }
        })

        console.log(sumLost);

        let diff = sumWin - sumLost;

        setGGR(diff.toFixed(2));
    }

    return (
        <MainLayout>
            <Filter
                filters={[
                    {
                        name: "search",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.selectInput,
                        predefinedValue: "clientId",
                        options: [
                            {
                                label: "ID da Jogador",
                                value: "clientId",
                            }
                        ],
                    },
                    {
                        name:"isCalcTime",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.select,
                        predefinedValue: "false",
                        options:[
                            {
                                label: "Criado",
                                value: "false"
                            },
                            {
                                label: "Calculado",
                                value: "true"
                            }
                        ]
                    },
                    {
                        name: "startDate,endDate",
                        placeholder: t("Pesquisar por") as string,
                        type: FTypes.range,
                        predefinedValue: [startOfDay(new Date()), endOfDay(addDays(new Date(), +1))],
                        format: 'DD-MM-YY'
                    }
                ]}
                loading={loading}
                onPress={handleFilter}
            />

            <TablePaginated
                data={list}
                totalItems={0}
                childrenTable={() => {
                    if (betTotals) {
                        return (
                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell><strong>R$ {betTotals?.EquivalentAmountSum}</strong></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell><strong>R$ {betTotals?.EquivalentWinningSum}</strong></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell><strong>{GGR ? parseFloat(GGR) > 0 ? `R$ (${GGR})` : `R$ ${parseFloat(GGR) * -1}` : ``}</strong></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        )
                    } else {
                        return null;
                    }
                }}
                keys={[
                    {
                        name: "id",
                        type: "string"
                    },
                    {
                        name: "client_id",
                        type: "number"
                    },
                    {
                        name: "bonus_id",
                        type: "number"
                    },
                    {
                        name: "amount",
                        sum: true,
                        type: "money"
                    },
                    {
                        name: "price",
                        type: "number"
                    },
                    {
                        name: "winning_amount",
                        sum: true,
                        type: "money"
                    },
                    {
                        name: "type_name",
                        type: "string"
                    },
                    {
                        name: "state_name",
                        type: "tag"
                    },
                    {
                        name: "client_first_name",
                        type: "string"
                    },
                    {
                        name: "client_last_name",
                        type: "string"
                    },
                    {
                        name: "client_login",
                        type: "string"
                    },
                ]}
                perPage={0}
                onPageChange={function (page: number): void {
                    throw new Error('Function not implemented.');
                }}
            />
            {filters && filters.limit !== totalItems && <Flex width={"100%"} align={"center"} gap={"10px"} justify={"center"} mt={"10px"}>
                {page !== 1 && <Button variant='soft' onClick={() => {
                    backPage();
                }}>Anterior</Button>}
                <div>{page}</div>
                <Button variant='soft' onClick={() => {
                    nextPage();
                }}>Próximo</Button>

                <Select.Root defaultValue="25" onValueChange={(e) => handleChangePerPage(e)}>
                    <Select.Trigger />
                    <Select.Content>
                        <Select.Group>
                            <Select.Label>{t('Select_Per_Page')}</Select.Label>
                            <Select.Item value="25">25</Select.Item>
                            <Select.Item value="50">50</Select.Item>
                            <Select.Item value="todos">Todos</Select.Item>
                        </Select.Group>
                    </Select.Content>
                </Select.Root>
            </Flex>}
            {filters && filters.limit === totalItems && <Flex width={"100%"} align={"center"} gap={"10px"} justify={"center"} mt={"10px"}><Select.Root defaultValue={String(perPage)} onValueChange={(e) => handleChangePerPage(e)}>
                <Select.Trigger />
                <Select.Content>
                    <Select.Group>
                        <Select.Label>{t('Select_Per_Page')}</Select.Label>
                        <Select.Item value="25">25</Select.Item>
                        <Select.Item value="50">50</Select.Item>
                        <Select.Item value="todos">Todos</Select.Item>
                    </Select.Group>
                </Select.Content>
            </Select.Root></Flex>}
        </MainLayout>
    );
}

export default ReportByPlayerSportbook;