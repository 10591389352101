import { Button, Dialog, Flex, Spinner } from "@radix-ui/themes";
import { BookmarkIcon } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "src/components/OtpInput";
import Auth from "src/infraestructure/services/api/Auth";

export type TModalLogin = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const ModalOtp = ({ open, setOpen }: TModalLogin) => {

    const [loading, setLoading] = useState<boolean>(false);
    const otpRef = useRef<{ clear: () => void }>(null);
    const { t } = useTranslation();

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>  
            <Dialog.Trigger style={{ display: 'none' }}>
                <Button>{t('Join')}</Button>
            </Dialog.Trigger>

            <Dialog.Content maxWidth="450px">
                <Dialog.Title>Auth2F</Dialog.Title>
                <Dialog.Description size="2" mb="4">
                    Realiza a verificação de 2 fatores
                </Dialog.Description>



                <OtpInput ref={otpRef} onComplete={async (pin: string) => {
                    setLoading(true);
                    const verifyOTP = await Auth.verifyTotop({
                        totopToken: pin
                    });
                    if(verifyOTP) {
                        if(!verifyOTP.status) {
                            setLoading(false);
                            otpRef.current?.clear()
                            // setOpen(false);
                            return;
                        }
                        setLoading(false);
                        setOpen(false);
                        window.location.reload();
                    } else {
                        setLoading(false);
                    }
                }} />

                <Flex gap="3" mt="4" justify="center">
                    {!loading && <Button variant="classic" type="submit">{t('Continue')}</Button>}
                    {loading && <Button disabled variant="classic">
                        <Spinner loading>
                            <BookmarkIcon />
                        </Spinner>
                        {t('Continue')}
                    </Button>}
                </Flex>
            </Dialog.Content>
        </Dialog.Root>

    );
}

export default ModalOtp;