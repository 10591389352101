class WebSocketService {
  private socket: WebSocket | null = null;
  private listeners: ((message: any) => void)[] = [];
  private url: string;
  private connectionPromise: Promise<void> | null = null;
  private activeSession: string | null = null;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;

  constructor(url: string) {
    this.url = url;
    // console.log("🔧 WebSocket Service iniciado com URL:", url);
  }

  connect(): Promise<void> {
    if (this.socket?.readyState === WebSocket.OPEN) {
      // console.log("🔄 WebSocket já está conectado");
      return Promise.resolve();
    }

    if (this.connectionPromise) {
      // console.log("⏳ Conexão WebSocket em andamento...");
      return this.connectionPromise;
    }

    // console.log("🔌 Iniciando conexão WebSocket...");

    this.connectionPromise = new Promise((resolve, reject) => {
      try {
        this.socket = new WebSocket(this.url);

        this.socket.onopen = () => {
          // console.log("✅ WebSocket conectado com sucesso!");
          this.reconnectAttempts = 0;
          this.connectionPromise = null;
          
          // Se havia uma sessão ativa, reconecta automaticamente
          if (this.activeSession) {
            // console.log("🔄 Reconectando sessão anterior:", this.activeSession);
            this.openSession(this.activeSession);
          }
          
          resolve();
        };

        this.socket.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            // console.log("📩 Mensagem recebida:", data);
            
            // Se receber mensagem de sessão já ativa, não tenta reconectar
            if (data.message === "A session is already active for this user_id.") {
              // console.log("⚠️ Sessão já está ativa para este usuário");
              this.activeSession = data.user_id;
            }
            
            this.listeners.forEach((listener) => listener(data));
          } catch (error) {
            console.error("❌ Erro ao processar mensagem:", error);
          }
        };

        this.socket.onerror = (error) => {
          console.error("❌ Erro na conexão WebSocket:", error);
          this.connectionPromise = null;
          reject(error);
        };

        this.socket.onclose = () => {
          console.log("🔴 Conexão WebSocket fechada");
          this.socket = null;
          this.connectionPromise = null;

          // Tenta reconectar apenas se não atingiu o limite de tentativas
          if (this.reconnectAttempts < this.maxReconnectAttempts) {
            this.reconnectAttempts++;
            const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 30000);
            // console.log(`🔄 Tentativa ${this.reconnectAttempts}/${this.maxReconnectAttempts} - Reconectando em ${delay/1000}s...`);
            setTimeout(() => this.connect(), delay);
          } else {
            console.log("❌ Máximo de tentativas de reconexão atingido");
            this.activeSession = null;
          }
        };
      } catch (error) {
        console.error("❌ Erro ao criar conexão WebSocket:", error);
        this.connectionPromise = null;
        reject(error);
      }
    });

    return this.connectionPromise;
  }

  openSession(userId: string) {
    if (this.activeSession === userId) {
      // console.log("⚠️ Sessão já está ativa para:", userId);
      return;
    }

    // console.log("🔑 Abrindo sessão para usuário:", userId);
    this.activeSession = userId;
    
    const message = {
      command: "open_session",
      user_id: userId
    };
    this.sendMessage(message);
  }

  pendingSolicitations(userId: string) {
    if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
      console.warn("⚠️ WebSocket não está conectado para buscar solicitações pendentes");
      return;
    }

    // console.log("🔍 Consultando solicitações pendentes para:", userId);
    const message = {
      command: "get_pending_solicitations",
      user_id: userId
    };
    this.sendMessage(message);
  }

  sendMessage(message: any) {
    if (this.socket?.readyState === WebSocket.OPEN) {
      const messageToSend = JSON.stringify(message);
      // console.log("📤 Enviando mensagem:", messageToSend);
      this.socket.send(messageToSend);
    } else {
      console.warn("⚠️ WebSocket não está conectado. Mensagem não enviada:", message);
    }
  }

  addListener(callback: (message: any) => void) {
    this.listeners.push(callback);
    // console.log("👂 Novo listener adicionado. Total:", this.listeners.length);
  }

  removeListener(callback: (message: any) => void) {
    const previousLength = this.listeners.length;
    this.listeners = this.listeners.filter((listener) => listener !== callback);
    // console.log("🗑️ Listener removido. Restantes:", this.listeners.length);
  }

  disconnect() {
    if (this.socket) {
      // console.log("👋 Desconectando WebSocket...");
      this.activeSession = null;
      this.socket.close();
      this.socket = null;
    }
  }
}

export const websocketService = new WebSocketService(process.env.REACT_APP_WEBSOCKET_URL || "");
// console.log("🚀 WebSocket Service exportado com URL:", process.env.REACT_APP_WEBSOCKET_URL || "");
