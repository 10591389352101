import { Badge, Table } from '@radix-ui/themes';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTablePaginatedStyles from 'src/styles/components/tablePaginatedStyles';
import { getColorFromStatus } from 'src/utils';

export type TKeysOptions = {
    name: string;
    type: string;
    imageSize?: number;
    sum?: boolean;
    calculate?: string;
    predefinedAmount?: number;
    calculateFunction?: (data: any) => void;
    children?: (data: any) => ReactNode;
}

type TTablePaginated = {
    totalItems: number;
    data?: any[];
    keys: TKeysOptions[];
    isPaginated?: boolean;
    perPage: number;
    childrenTable?: () => ReactNode;
    onPageChange: (page: number) => void;
}

const TablePaginated = ({ data, keys, isPaginated, perPage, onPageChange, totalItems, childrenTable }: TTablePaginated) => {
    const { PaginateContainer, PageItem } = useTablePaginatedStyles();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { t } = useTranslation();
    const pageCount = Math.ceil(totalItems / perPage) ?? 0;
    const pageArray = totalItems > 0 ? Array.from(Array(pageCount).keys()) : [];

    const viewPages = () => {
        const halfPageLimit = Math.floor(8 / 2);

        let startPage = Math.max(1, currentPage - halfPageLimit);
        let endPage = Math.min(pageCount, currentPage + halfPageLimit);

        let pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(<div key={i} onClick={() => {
                setCurrentPage(i);
                onPageChange(i);
            }} className={currentPage === i ? `${PageItem} active` : PageItem}>
                {i}
            </div>)
        }

        return pageNumbers;
    }

    const sumKey = (key: string) => {
        let currentValue = 0;
        data?.map((i: any) => {
            currentValue += i[key]
        })

        return currentValue;
    }

    const sumKeySportbook = (key: string) => {
        let currentValue = 0;
        data?.map((i: any) => {
            if(i.StateName !== "CashOut") {
                currentValue += i[key]
            }
        })

        return currentValue.toFixed(2);
    }

    const getCalculatedValue = (calculate: string) => {
        let splittedValue = calculate.split(",");
        let method = splittedValue[0];
        let firstValue = splittedValue[1];
        let secondValue = splittedValue[2];

        let sumFirst = 0;
        data?.map((i: any) => {
            sumFirst += i[firstValue]
        });

        let sumSecond = 0;
        data?.map((i: any) => {
            sumSecond += i[secondValue]
        });

        if(method === "substract") {
            return sumFirst - sumSecond;
        } else {
            return sumFirst + sumSecond;
        }
    }    


    return (
        <>
            <Table.Root mt={"20px"} variant="surface">
                <Table.Header>
                    <Table.Row>
                        {keys.map((i, idx) => {
                            return (
                                <Table.ColumnHeaderCell key={idx}>{t(i.name)}</Table.ColumnHeaderCell>
                            )
                        })}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data?.map((i, index) => {
                        return (
                            <Table.Row key={index}>
                                {keys.map((s, inx) => {
                                    if (s.children) {
                                        return (
                                            <Table.Cell key={inx}>
                                                {s.children(i)}
                                            </Table.Cell>
                                        )
                                    } else {
                                        return (
                                            <Table.Cell key={inx}>
                                                {s.type === "image" && s.imageSize &&
                                                    <img src={i[s.name]} height={s.imageSize} />
                                                }
                                                {s.type !== "date" && s.type !== "tag" && s.type === "money" ? 'R$' : ''}{s.type !== "date" && s.type !== "tag" && s.type !== "status" && s.type !== "image" && t(s.type === "money" ? typeof i[s.name] === "number" ? i[s.name].toFixed(2) : i[s.name] : i[s.name])}
                                                {s.type === "tag" &&
                                                    <Badge color={getColorFromStatus(t(i[s.name]))}>
                                                        {t(i[s.name])}
                                                    </Badge>
                                                }
                                                {s.type === "status" && t(i[s.name])}
                                                {s.type === "date" && moment(i[s.name]).format("DD/MM/YYYY HH:mm:ss")}
                                            </Table.Cell>
                                        )
                                    }
                                })}
                            </Table.Row>
                        )
                    })}
                    {keys.some(obj => 'sum' in obj) && <Table.Row>
                        {keys.map((s, index) => {
                            if(s.name !== "amount" && s.name !== "winning_amount") {
                                return(
                                    <Table.Cell key={index}>
                                        <div style={{ fontWeight: 'bold' }}>{s.predefinedAmount ? `R$ ${s.predefinedAmount}` : s.sum ? s.calculate ? `R$ ${getCalculatedValue(s.calculate)}` : `R$ ${sumKey(s.name)}` : ""}</div>
                                    </Table.Cell>
                                )
                            } else {
                                return(
                                    <Table.Cell key={index}>
                                        <div style={{ fontWeight: 'bold' }}>{s.predefinedAmount ? `R$ ${s.predefinedAmount}` : s.sum ? s.calculate ? `R$ ${getCalculatedValue(s.calculate)}` : `R$ ${sumKeySportbook(s.name)}` : ""}</div>
                                    </Table.Cell>
                                )
                            }
                        })}
                    </Table.Row>}
                </Table.Body>
                {childrenTable ? childrenTable() : null}
            </Table.Root>
            {pageCount > 1 && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={PaginateContainer}>
                    {viewPages()}
                </div>
            </div>}
        </>
    );
}

export default TablePaginated;