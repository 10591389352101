import {
  AlertDialog,
  Badge,
  Button,
  Flex,
  IconButton,
  Text,
  Theme,
} from "@radix-ui/themes";
import Modal from "react-modal";
import { EditIcon, PlusIcon, TrashIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { TUserInfoResponse } from "src/@types/user";
import MainLayout from "src/components/MainLayout";
import TablePaginated from "src/components/TablePaginated";
import { ToggleSwitch } from "src/components/ui/ToggleSwitch";
import AdminUsersList from "src/infraestructure/services/api/Admin/Users";
import toast from "react-hot-toast";

const AdminUsers = () => {
  const [usersList, setUsersList] = useState<TUserInfoResponse[]>();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<TUserInfoResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  Modal.setAppElement("#root");

  const customStyles = {
    modal: {
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.667)",
        zIndex: 998,
      },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        background: "#1e2024",
        minHeight: 250,
        maxHeight: 250,
        minWidth: 450,
        maxWidth: 450,
        display: "flex",
        borderRadius: 12,
        justifyContent: "center",
        border: "unset !important",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "30px 50px",
      },
    },
  };

  useEffect(() => {
    fetchAdminUsers();
  }, []);

  const handleBlockUser = async () => {
    if (userSelected) {
      setIsLoading(true);
      const requestUsers = await AdminUsersList.edit(userSelected?.user_id, {
        email: userSelected?.email,
        name: userSelected?.name,
        projects: userSelected?.projects,
        role: userSelected?.role,
        blocked: !userSelected?.blocked,
      });

      if (requestUsers.status === 200) {
        toast.success(t(requestUsers.message));
        setIsOpenModal(false);
        fetchAdminUsers(page);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  const fetchAdminUsers = async (page?: number) => {
    const requestUsers = await AdminUsersList.get({
      page: page ? page : 1,
      limit: 20,
    });

    if (requestUsers.status === 200) {
      setTotalItems(requestUsers.pagination.total_users);
      setUsersList(requestUsers.data as TUserInfoResponse[]);
      setPage(requestUsers.pagination.current_page);
    }
  };

  const deleteUser = async (userId: string) => {
    const remove = await AdminUsersList.remove(userId);
    if (remove.status === 200 || remove.status === 201) {
      fetchAdminUsers();
    }
  };

  return (
    <MainLayout>
      <Modal
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        style={customStyles.modal}
      >
        <Theme
          style={{
            width: "100%",
            minHeight: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Flex
            display={"flex"}
            direction={"column"}
            justify={"between"}
            align={"center"}
            width={"100%"}
            height={"100%"}
            style={{ padding: 10 }}
          >
            <Flex
              height={"50%"}
              direction={"column"}
              align={"center"}
              justify={"between"}
            >
              <p style={{ fontSize: 20, fontWeight: 700 }}>
                {" "}
                {userSelected?.blocked ? "Desbloquear" : "Bloquear"} usuário
              </p>
              <Text as="p" size="2" style={{ marginBottom: 10 }}>
                Tem certeza que deseja{" "}
                {userSelected?.blocked ? "desbloquear" : "bloquear"} o usuário{" "}
                <strong>{userSelected?.name}</strong>?
              </Text>
              <Text as="p" size="2" align={"center"}>
                {userSelected?.blocked
                  ? " Ao desbloquear, ele poderá acessar o sistema normalmente."
                  : " Após o bloqueio, ele não poderá mais acessar o sistema até ser desbloqueado."}
              </Text>
            </Flex>
            <Flex width={"80%"} justify={"between"} align={"end"}>
              <Button
                size="3"
                variant="soft"
                color="gray"
                onClick={() => setIsOpenModal(false)}
              >
                Cancelar
              </Button>
              <Button
                size="3"
                variant="soft"
                color="red"
                onClick={handleBlockUser}
                loading={isLoading}
                disabled={isLoading}
              >
                {userSelected?.blocked ? "Desbloquear" : "Bloquear"}
              </Button>
            </Flex>
          </Flex>
        </Theme>
      </Modal>
      <Button
        onClick={() => {
          navigate("/admin/create");
        }}
      >
        <PlusIcon /> {t("Criar Usuário")}
      </Button>
      <TablePaginated
        totalItems={totalItems}
        keys={[
          {
            name: "avatar_url",
            type: "image",
            imageSize: 50,
          },
          {
            name: "user_id",
            type: "string",
          },
          {
            name: "name",
            type: "string",
          },
          {
            name: "role",
            type: "tag",
            children: (response) => {
              return <Badge color={"blue"}>{response.role}</Badge>;
            },
          },
          {
            name: "blocked",
            type: "tag",
            children: (response) => {
              return (
                // <Badge color={response.blocked ? "green" : "red"}>
                //   {response.blocked ? "Sim" : "Não"}
                // </Badge>
                <Text as="label" size="2">
                  <Flex gap="2">
                    <ToggleSwitch
                      isEnabled={response.blocked}
                      onChange={() => {
                        setUserSelected(response);
                        setIsOpenModal(true);
                      }}
                    />
                  </Flex>
                </Text>
              );
            },
          },
          {
            name: "actions",
            type: "string",
            children: (response) => {
              return (
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <IconButton
                    size="1"
                    aria-label="Copy value"
                    color="gray"
                    onClick={() => {
                      navigate(`/admin/edit/${response.user_id}`);
                    }}
                    variant="ghost"
                  >
                    <EditIcon />
                  </IconButton>
                  <AlertDialog.Root>
                    <AlertDialog.Trigger>
                      <IconButton
                        size="1"
                        aria-label="Copy value"
                        color="gray"
                        variant="ghost"
                      >
                        <TrashIcon />
                      </IconButton>
                    </AlertDialog.Trigger>
                    <AlertDialog.Content maxWidth="450px">
                      <AlertDialog.Title>Deletar Usuário</AlertDialog.Title>
                      <AlertDialog.Description size="2">
                        Tem certeza que deseja deletar o usuário?
                      </AlertDialog.Description>

                      <Flex gap="3" mt="4" justify="end">
                        <AlertDialog.Cancel>
                          <Button variant="soft" color="gray">
                            Cancelar
                          </Button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action>
                          <Button
                            onClick={() => {
                              deleteUser(response.user_id);
                            }}
                            variant="solid"
                            color="red"
                          >
                            Deletar
                          </Button>
                        </AlertDialog.Action>
                      </Flex>
                    </AlertDialog.Content>
                  </AlertDialog.Root>
                </div>
              );
            },
          },
        ]}
        data={usersList}
        perPage={20}
        onPageChange={(page: number) => {
          fetchAdminUsers(page);
        }}
      />
    </MainLayout>
  );
};

export default AdminUsers;
