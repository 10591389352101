import { Label } from "@radix-ui/react-label";
import {
  Box,
  Button,
  Flex,
  Text,
  Select,
  Spinner,
  TextField,
} from "@radix-ui/themes";
import { ArrowLeftCircleIcon, BookmarkIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { TDaysData, TProjectsResponse } from "src/@types/projects";
import { TCreateUserRequest, TUserInfoResponse } from "src/@types/user";
import { DatePicker } from "rsuite";
import ControlledCheckboxes from "src/components/ControlledCheckboxes";
import MainLayout from "src/components/MainLayout";
import Auth from "src/infraestructure/services/api/Auth";
import Projects from "src/infraestructure/services/api/Projects";
import userProfileStyles from "src/styles/components/userProfileStyles";
import "../../styles/daterangepicker.css";
import { adjustHours, convertToFullDate, extractDate } from "src/utils";
import Users from "src/infraestructure/services/api/Admin/Users";
import toast from "react-hot-toast";

const CreateUser = () => {
  const { BackButtonStyle } = userProfileStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [projectsList, setProjectsList] = useState<TProjectsResponse[]>();
  const [daysList, setDaysList] = useState<TDaysData[]>();
  const [currentUser, setCurrentUser] = useState<TUserInfoResponse>();

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TCreateUserRequest>();
  const role = watch("role");

  const onSubmit: SubmitHandler<TCreateUserRequest> = async (data) => {
    const requestData =
      role === "ADMINISTRATOR" || role === "MODERATOR"
        ? {
            name: data.name,
            email: data.email,
            projects: data.projects,
            role: data.role,
          }
        : {
            name: data.name,
            email: data.email,
            projects: data.projects,
            role: data.role,
            days: data.days,
            startTime: data.startTime ? adjustHours(data.startTime) : null,
            startDate: data.startDate ? extractDate(data.startDate) : null,
            endTime: data.endTime ? adjustHours(data.endTime) : null,
          };

    data.projects = data.projects.filter((p) => p !== null && p !== undefined);

    if (id) {
      setLoading(true);
      try {
        const request = await Users.edit(id, requestData);
        toast.success(t(request.message));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        const request = await Users.save(requestData);
        toast.success(t(request.message));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserById(id);
    }
    setDaysList([
      { id: "Monday", name: "Segunda" },
      { id: "Tuesday", name: "Terça" },
      { id: "Wednesday", name: "Quarta" },
      { id: "Thursday", name: "Quinta" },
      { id: "Friday", name: "Sexta" },
      { id: "Saturday", name: "Sabado" },
      { id: "Sunday", name: "Domingo" },
    ]);
    fetchProjects();
  }, []);

  const fetchUserById = async (id: string) => {
    const getUserInfo = await Auth.getById({
      userId: id,
    });

    if (getUserInfo.status === 200) {
      const userInfoData: TUserInfoResponse = getUserInfo.data;
      setValue("email", userInfoData.email);
      setValue("name", userInfoData.name);
      setValue("role", userInfoData.role);
      setValue("projects", userInfoData.projects);
      if (userInfoData.schedule) {
        const date = new Date(userInfoData.schedule[0].start_date);
        date.setHours(date.getUTCHours() + +24);

        setValue("startDate", date.toString());
        setValue(
          "startTime",
          convertToFullDate(userInfoData.schedule[0]?.start_time)
        );
        setValue(
          "endTime",
          convertToFullDate(userInfoData.schedule[0]?.end_time)
        );
        setValue("days", userInfoData.days);
      }
      setCurrentUser(userInfoData);
    }
  };

  const fetchProjects = async () => {
    const requestProjects = await Projects.get();
    setProjectsList(requestProjects.data);
  };

  return (
    <MainLayout>
      <div
        className={BackButtonStyle}
        onClick={() => {
          navigate("/admin/users");
        }}
      >
        <ArrowLeftCircleIcon />
        <Text>{t("Voltar")}</Text>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          style={{
            background: "var(--color-panel)",
            borderRadius: 10,
            paddingBottom: 20,
          }}
        >
          <Box
            style={{
              padding: 30,
              background: "#222227",
              height: "150px",
              borderRadius: "10px 10px 0 0",
              marginBottom: 50,
            }}
          >
            <Flex direction={"column"} gap={"20px"}>
              <Text style={{ fontWeight: "bold", fontSize: 20 }}>
                {!id ? t("Create new user") : t("Edit user")}
              </Text>
              <Text style={{ fontWeight: "500", fontSize: 15 }}>
                {!id
                  ? t("Fill in the details below")
                  : t("Fill in the details below edit")}
              </Text>
            </Flex>
          </Box>
          <Flex
            direction={"column"}
            gap={"30px"}
            width={"30%"}
            style={{ padding: "0 50px 0 50px", marginBottom: 30 }}
          >
            <Flex direction={"column"} gap={"10px"}>
              <Label style={{ fontSize: 14, fontWeight: "bold" }}>
                Nome do Usuário/Prestador
              </Label>
              <TextField.Root
                {...register("name", {
                  required: true,
                })}
                size="2"
                placeholder="Insira aqui o nome do usuário."
              />
              {errors.name && (
                <p style={{ color: "red", fontSize: 12 }}>
                  {t("Name_Required")}
                </p>
              )}
            </Flex>
            <Flex direction={"column"} gap={"10px"}>
              <Label style={{ fontSize: 14, fontWeight: "bold" }}>E-mail</Label>
              <TextField.Root
                {...register("email", {
                  required: true,
                })}
                size="2"
                placeholder="Insira e-mail."
              />
              {errors.email && (
                <p style={{ color: "red", fontSize: 12 }}>
                  {t("Email_Required")}
                </p>
              )}
            </Flex>
            <Flex direction={"column"} gap={"10px"}>
              <Label style={{ fontSize: 14, fontWeight: "bold" }}>Cargo</Label>

              <Controller
                control={control}
                name="role"
                defaultValue={id ? currentUser?.role : undefined}
                rules={{
                  required: true,
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  formState,
                  fieldState,
                }) => (
                  <Select.Root value={value} onValueChange={onChange}>
                    <Select.Trigger />
                    <Select.Content>
                      <Select.Group>
                        <Select.Label>Função</Select.Label>
                        <Select.Item value="ADMINISTRATOR">
                          ADMINISTRADOR
                        </Select.Item>
                        <Select.Item value="MODERATOR">MODERADOR</Select.Item>
                        <Select.Item value="SUPPORT_LEVEL_1">
                          SUPORTE LEVEL 1
                        </Select.Item>
                        <Select.Item value="SUPPORT_LEVEL_2">
                          SUPORTE LEVEL 2
                        </Select.Item>
                        <Select.Item value="SUPPORT_LEVEL_3">
                          SUPORTE LEVEL 3
                        </Select.Item>
                        <Select.Item value="SUPPORT_LEVEL_4">
                          SUPORTE LEVEL 4
                        </Select.Item>
                      </Select.Group>
                    </Select.Content>
                  </Select.Root>
                )}
              />
              {errors.role && (
                <p style={{ color: "red", fontSize: 12 }}>
                  {t("Role_Required")}
                </p>
              )}
            </Flex>
            <h3>{t("Projects")}</h3>
            {projectsList && watch("projects") && id && (
              <ControlledCheckboxes
                options={projectsList}
                control={control}
                name="projects"
              />
            )}
            {projectsList && !id && (
              <ControlledCheckboxes
                options={projectsList}
                control={control}
                name="projects"
              />
            )}
          </Flex>
          {role !== "ADMINISTRATOR" && role !== "MODERATOR" && (
            <>
              <Flex
                direction={"row"}
                width={"100%"}
                justify={"between"}
                align={"center"}
                style={{ padding: "0 50px 0 50px", marginBottom: 50 }}
              >
                <Flex
                  direction={"column"}
                  gap={"10px"}
                  width={"50%"}
                  style={{ marginRight: 80 }}
                >
                  <Text style={{ fontWeight: "bold", fontSize: 20 }}>
                    Data de ínicio:
                  </Text>
                  <Text
                    style={{
                      fontWeight: "500",
                      fontSize: 15,
                      marginBottom: 20,
                    }}
                  >
                    Escolha a data em que o prestador começará neste turno ou
                    produto.
                  </Text>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{
                      required:
                        role !== "ADMINISTRATOR" && role !== "MODERATOR",
                    }}
                    render={({ field }) => (
                      <DatePicker
                        format="dd/MM/yyyy"
                        placeholder="Selecione uma data"
                        value={field.value ? new Date(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? date.toISOString() : null)
                        }
                        onSelect={(date) =>
                          field.onChange(date ? date.toISOString() : null)
                        }
                        cleanable
                      />
                    )}
                  />
                  {errors.startDate && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      {t("StartDate_Required")}
                    </p>
                  )}
                </Flex>
                <Flex direction={"column"} gap={"10px"} width={"50%"}>
                  <Text style={{ fontWeight: "bold", fontSize: 20 }}>
                    Horários:
                  </Text>
                  <Text style={{ fontWeight: "500", fontSize: 15 }}>
                    Informe o horário de início e término do trabalho do
                    prestador.
                  </Text>
                  <Flex justify={"between"} className="rs-theme-dark">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "40%",
                        position: "relative",
                      }}
                      id="box-hours-user-1"
                    >
                      <Controller
                        name="startTime"
                        control={control}
                        rules={{
                          required:
                            role !== "ADMINISTRATOR" && role !== "MODERATOR",
                        }}
                        render={({ field }) => (
                          <DatePicker
                            format="HH:mm"
                            value={field.value ? new Date(field.value) : null}
                            onChange={(date) =>
                              field.onChange(date ? date.toISOString() : null)
                            }
                            onSelect={(date) =>
                              field.onChange(date ? date.toISOString() : null)
                            }
                            cleanable
                            placeholder="Horário de Início"
                            style={{ marginTop: "10px" }}
                            shouldDisableMinute={() => false}
                            shouldDisableSecond={() => false}
                            ranges={[]}
                            container={() =>
                              document.getElementById("box-hours-user-1")!
                            }
                          />
                        )}
                      />
                      {errors.startTime && (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {t("StartTime_Required")}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "40%",
                        position: "relative",
                      }}
                      id="box-hours-user-2"
                    >
                      <Controller
                        name="endTime"
                        control={control}
                        rules={{
                          required:
                            role !== "ADMINISTRATOR" && role !== "MODERATOR",
                        }}
                        render={({ field }) => (
                          <DatePicker
                            format="HH:mm"
                            value={field.value ? new Date(field.value) : null}
                            onChange={(date) =>
                              field.onChange(date ? date.toISOString() : null)
                            }
                            onSelect={(date) =>
                              field.onChange(date ? date.toISOString() : null)
                            }
                            cleanable
                            placeholder="Horário de Saída"
                            style={{ marginTop: "10px" }}
                            shouldDisableMinute={() => false}
                            shouldDisableSecond={() => false}
                            ranges={[]}
                            container={() =>
                              document.getElementById("box-hours-user-2")!
                            }
                          />
                        )}
                      />
                      {errors.endTime && (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {t("EndTime_Required")}
                        </p>
                      )}
                    </div>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                direction={"column"}
                gap={"10px"}
                width={"50%"}
                style={{ padding: "0 50px 0 50px", marginBottom: 50 }}
              >
                <Text style={{ fontWeight: "bold", fontSize: 20 }}>
                  Dias da Semana
                </Text>
                <Text style={{ fontWeight: "500", fontSize: 15 }}>
                  Selecione os dias da semana em que o prestador atuará.
                </Text>
                {daysList && id && (
                  <ControlledCheckboxes
                    gridTemplate="repeat(7, 10rem)"
                    options={daysList}
                    control={control}
                    name="days"
                  />
                )}
                {daysList && !id && (
                  <ControlledCheckboxes
                    gridTemplate="repeat(7, 10rem)"
                    options={daysList}
                    control={control}
                    name="days"
                  />
                )}
              </Flex>
            </>
          )}
          <Flex direction={"column"} gap={"10px"} style={{ margin: 50 }}>
            <Button type="submit" variant="classic" disabled={loading}>
              {loading && (
                <Spinner loading>
                  <BookmarkIcon />
                </Spinner>
              )}
              {!id ? "Criar Usuário" : "Editar Usuário"}
            </Button>
          </Flex>
        </Box>
      </form>
    </MainLayout>
  );
};

export default CreateUser;
