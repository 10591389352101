import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import './OtpInput.css';

type InputProps = {
  length?: number;
  onComplete: (pin: string) => void;
};

type OTPInputRef = {
  clear: () => void;
};

const OTPInput = forwardRef<OTPInputRef, InputProps>(({ length = 6, onComplete }, ref) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [OTP, setOTP] = useState<string[]>(Array(length).fill(''));

  const handleTextChange = (input: string, index: number) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (input.length === 1 && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (input.length === 0 && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }

    if (newPin.every((digit) => digit !== '')) {
      onComplete(newPin.join(''));
    }
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      setOTP(Array(length).fill(''));
      inputRefs.current[0]?.focus();
    },
  }));

  return (
    <div className="otp-group">
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el!)}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={1}
          className="otp-input"
          onChange={(e) => handleTextChange(e.target.value, index)}
          value={OTP[index]}
          onKeyDown={(e) => {
            if (e.key === 'Backspace' && !OTP[index] && index > 0) {
              inputRefs.current[index - 1]?.focus();
            }
          }}
        />
      ))}
    </div>
  );
});

export default OTPInput;