import Gateway from "../../Gateway";

const routeSolicitations = "/v1/solicitations";
const routeUpdateStatus = "/v1/solicitations/status/:id/:type";
const routeOpenSolicitation = "/v1/solicitations/create-solicitation";

const get = async (data: { page: number; limit: number }) =>
  Gateway({
    url: routeSolicitations,
    filters: [
      {
        name: "page",
        value: data.page as any,
      },
      {
        name: "limit",
        value: data.limit as any,
      },
    ],
  });

  const post = async (data: {requestor_id: string, justification: string}) => Gateway({
    url: routeOpenSolicitation,
    method: "POST",
    data,
  })

const put = async (id: string, type: string) =>
  Gateway({
    url: routeUpdateStatus,
    method: "PUT",
    id,
    type,
  });

export default {
  get,
  post,
  put,
};
