import { Theme, ThemePanel } from '@radix-ui/themes';
import { Suspense, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { getAuthRouters } from 'react-router-auth-plus';
import { createBrowserRouter, Navigate, RouterProvider, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import './App.css';
import Loading from './components/Loading';
import { routers } from './config/route/routers';
import Unauthorized from './pages/Unauthorized';
import { color, isTokenExpired } from './utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ModalLogin from './components/ModalLogin';
import { AuthProvider } from './contexts/auth';
import { TUserInfoResponse } from './@types/user';
import GenerateAvatar from './infraestructure/services/api/GenerateAvatar';
import ModalOtp from './components/ModalOtp';
import { ModalProvider, useModal } from './contexts/ModalContext';
import useAuthInterceptor from './hooks/useAuthInterceptor';

const fetcher = async (url: string): Promise<string[]> =>
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(["admin"]);
    }, 1000);
  });

const queryClient = new QueryClient()

export function RequireAuth({
  children,
  path,
  roles
}: {
  children: JSX.Element;
  path: string;
  roles: string[];
}) {

  const storagedToken = localStorage.getItem("@App:token");
  const storagedUser = localStorage.getItem("@App:user");

  const location = useLocation();

  if (process.env.REACT_APP_ENV === "PROD" && storagedUser) {
    const userInfo: TUserInfoResponse = JSON.parse(storagedUser);

    if (!roles.includes(userInfo.role)) {
      console.log(userInfo.role);
      return <Navigate to={`/access-denied`} state={{ from: location }} />;
    }
  }

  if (!storagedToken) {
    return (
      <Navigate
        to={`/login?redirect=${location.pathname}${location.search}`}
        state={{ from: location }}
      />
    );
  }

  return children;
}

const GlobalModals = () => {
  const { isOpen, closeModal } = useModal();
  return <ModalOtp open={isOpen} setOpen={closeModal} />;
};

function App() {

  const [open, setOpen] = useState<boolean>(false);

  const { data: auth, isValidating } = useSWR("/api/user", fetcher, {
    revalidateOnFocus: false,
  });

  const _routers = getAuthRouters({
    routers,
    noAuthElement: (router) => <Unauthorized />,
    render: (element) => (isValidating ? <Loading /> : element),
    auth: auth || [],
  });

  useEffect(() => {
    if (localStorage.getItem('@App:token')) {
      const token = localStorage.getItem('@App:token');
      if (token && isTokenExpired(token)) {
        localStorage.removeItem('@App:token');
        setOpen(true);
      }
    }
  }, [_routers]);

  // useAuthInterceptor();

  return (
    <ModalProvider>
      <Suspense>
        <Theme appearance="dark" panelBackground="solid">
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <ModalLogin open={open} setOpen={setOpen} />
              <RouterProvider
                router={createBrowserRouter(_routers)}
                // route loader loading
                fallbackElement={<Loading />}
              />
              <Toaster
                position="bottom-right"
                containerStyle={{ zIndex: 99999 }}
                toastOptions={{
                  style: {
                    padding: 20,
                    background: color('color-panel'),
                    color: '#fff',
                    zIndex: 99999999 // Certifique-se de que esse valor seja maior que o z-index do modal
                  }
                }}
              />
            </QueryClientProvider>
            <GlobalModals />
          </AuthProvider>
        </Theme>
      </Suspense>
    </ModalProvider>
  );
}

export default App;
