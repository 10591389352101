import * as Switch from "@radix-ui/react-switch";
import styled from "styled-components";

interface ToggleSwitchProps {
  isEnabled?: boolean;
  onChange?: (isEnabled: boolean) => void;
  className?: string;
}

interface StyledLabelProps {
  isEnabled: boolean;
}

const COLORS = {
  enabled: {
    background: "rgba(020, 202, 116, 0.22)",
    text: "#14ca74",
  },
  disabled: {
    background: "rgba(255, 090, 101, 0.22)",
    text: "#ff5a65",
  },
  container: {
    background: "rgba(130, 130, 130, 0.11)",
  },
} as const;

const SwitchContainer = styled(Switch.Root)`
  width: 70px;
  height: 30px;
  background-color: ${COLORS.container.background};
  border-radius: 6px;
  position: relative;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  border: none;
`;

const SwitchKnob = styled(Switch.Thumb)`
  display: flex;
  width: 25px;
  height: 25px;
  background-color: ${COLORS.disabled.background};
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, background-color 0.2s;
  transform: translateX(0);
  justify-content: center;
  align-items: center;

  &[data-state="checked"] {
    transform: translateX(37px);
    background-color: ${COLORS.enabled.background};
  }
`;

const SwitchLabel = styled.span<StyledLabelProps>`
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: ${({ isEnabled }) => 
    isEnabled ? COLORS.enabled.text : COLORS.disabled.text
  };
`;
export function ToggleSwitch({
  isEnabled = false,
  onChange,
  className,
}: ToggleSwitchProps) {
  return (
    <SwitchContainer
      checked={isEnabled}
      onCheckedChange={onChange}
      className={className}
    >
      <SwitchKnob>
        <SwitchLabel isEnabled={isEnabled}>
          {isEnabled ? "Sim" : "Não"}
        </SwitchLabel>
      </SwitchKnob>
    </SwitchContainer>
  );
}
