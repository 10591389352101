import { TReportRequestType } from "src/@types/reports";
import Gateway from "../Gateway";

const route = "/v1/clients/:id/transactions/:type";
const routeSportbook = "/v1/clients/bet-report";
const routeHistorySportbook = "/v1/clients/bet-history";
const getReport = async (data: TReportRequestType) => Gateway({
    url: route, id: data.clientId, type: data.type,
    filters: [
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "limit",
            value: data.limit as any,
        }
    ]
});

const getReportBet = async (data: TReportRequestType) => Gateway({
    url: routeSportbook,
    filters: [
        {
            name: "betId",
            value: data.betId as any,
        },
        {
            name: "clientId",
            value: data.clientId as any,
        },
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "limit",
            value: data.limit as any,
        },
        {
            name: "isCalcTime",
            value: data.isCalcTime as any
        }
    ]
});

const getHistoryBet = async (data: TReportRequestType) => Gateway({
    url: routeHistorySportbook,
    filters: [
        {
            name: "betId",
            value: data.betId as any,
        },
        {
            name: "clientId",
            value: data.clientId as any,
        },
        {
            name: "startDate",
            value: data.startDate as any,
        },
        {
            name: "endDate",
            value: data.endDate as any,
        },
        {
            name: "page",
            value: data.page as any,
        },
        {
            name: "limit",
            value: data.limit as any,
        },
        {
            name: "isCalcTime",
            value: data.isCalcTime as any
        },
        {
            name: "isBonus",
            value: data.isBonus as any
        }
    ]
});

export default {
    getReport,
    getReportBet,
    getHistoryBet
};