import {
  Avatar,
  Badge,
  Button,
  Card,
  Code,
  DataList,
  Flex,
  Grid,
  IconButton,
  Skeleton,
  Strong,
  Text,
  Theme,
} from "@radix-ui/themes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Clients from "src/infraestructure/services/api/Clients";
import Modal from "react-modal";
import { TClientsSearchResponse } from "src/@types/clients";
import { CopyIcon, MapIcon, X } from "lucide-react";
import toast from "react-hot-toast";
import { copyToClipBoard, formatCurrency } from "src/utils";
import userProfileStyles from "src/styles/components/userProfileStyles";
import { CardDescription, CardTitle } from "../ui/card";

Modal.setAppElement("#root");

const OldAccount = ({ id }: { id: number | null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [oldAccount, setOldAccount] = useState<TClientsSearchResponse>();
  const { CardUserInfoContainer, ContainerCityInfo, CardWrapper } =
    userProfileStyles();
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.667)",
      zIndex: 998,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      background: "#1e2024",
      minHeight: 200,
      minWidth: 900,
      display: "flex",
      borderRadius: 10,
      justifyContent: "center",
      border: "unset !important",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    button: {
      background: "#26262C",
      padding: "10px",
      width: "fit-content",
      height: "fit-content",
      color: "#9CA3AF",
      borderRadius: 50,
      cursor: "pointer",
      marginTop: 30,
    },
  };

  const { t } = useTranslation();

  const handleConsultOldAccount = async () => {
    if (id) {
      setIsLoading(true);
      try {
        const oldAccount = await Clients.getOldAccount(id);

        if (oldAccount.status === 200) {
          setIsOpen(true);
          setOldAccount(oldAccount.data);
        } else {
          setOldAccount(undefined);
          toast.error("Conta não encontrada");
        }

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setOldAccount(undefined);
        setIsLoading(false);
      }
    } else {
      toast.error("Conta não encontrada");
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          handleConsultOldAccount();
        }}
        variant="classic"
        color="green"
        loading={isLoading}
      >
        {t("Consult_Old_Account")}
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        ariaHideApp={true}
      >
        <Theme
          style={{
            width: "100%",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Flex
            direction={"row"}
            justify={"end"}
            align={"center"}
            minHeight={"fit-content"}
            minWidth={"100%"}
            style={{ marginBottom: 7, marginTop: 20 }}
          >
            <Button
              type="button"
              style={customStyles.button}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <X />
            </Button>
          </Flex>
          <Flex
            style={{
              width: "100%",
              minHeight: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {oldAccount && !isLoading && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {oldAccount && !isLoading && (
                    <Card className={CardUserInfoContainer}>
                      {oldAccount && oldAccount.name && (
                        <Avatar
                          radius="full"
                          fallback={oldAccount?.name[0].toUpperCase() as string}
                          size={"8"}
                        />
                      )}
                      <Flex align={"center"} gap={"2"}>
                        <Text size={"4"}>
                          <Strong>{oldAccount?.name}</Strong>
                        </Text>
                      </Flex>
                      <div className={ContainerCityInfo}>
                        <MapIcon />
                        <Text as="p">{oldAccount?.city}</Text>
                      </div>
                      <DataList.Root mt={"20px"}>
                        <DataList.Item>
                          <DataList.Label minWidth="88px">ID</DataList.Label>
                          <DataList.Value>
                            <Flex align="center" gap="2">
                              <Code variant="ghost">{oldAccount?.id}</Code>
                              <IconButton
                                size="1"
                                aria-label="Copy value"
                                color="gray"
                                onClick={() => {
                                  if (oldAccount.id) {
                                    copyToClipBoard(String(oldAccount.id));
                                    toast.success(`Copiado com sucesso!`);
                                  }
                                }}
                                variant="ghost"
                              >
                                <CopyIcon />
                              </IconButton>
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>
                        <DataList.Item>
                          <DataList.Label minWidth="88px">
                            Saldo Conta Antiga
                          </DataList.Label>
                          <DataList.Value>
                            <Badge color="green">
                              {formatCurrency(oldAccount.balance)}
                            </Badge>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">
                            Gênero
                          </DataList.Label>
                          <DataList.Value>
                            <Flex align={"center"} gap="2">
                              {t(oldAccount?.gender)}
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">
                            Telefone
                          </DataList.Label>
                          <DataList.Value>
                            <Flex align={"center"} gap="2">
                              {oldAccount?.phone ? oldAccount?.phone : "-"}
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">Login</DataList.Label>
                          <DataList.Value>
                            <Flex align={"center"} gap="2">
                              {oldAccount?.login}
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">Email</DataList.Label>
                          <DataList.Value>
                            <Flex align={"center"} gap="2">
                              {oldAccount?.email ? oldAccount?.email : "-"}
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">
                            Nascimento
                          </DataList.Label>
                          <DataList.Value>
                            <Flex align={"center"} gap="2">
                              {oldAccount?.birth_date
                                ? oldAccount?.birth_date
                                : ""}
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">
                            Categoria Jogador
                          </DataList.Label>
                          <DataList.Value>
                            <Badge>{oldAccount?.sportsbook_profile}</Badge>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">
                            Data Registro
                          </DataList.Label>
                          <DataList.Value>
                            {oldAccount?.created && (
                              <Badge>{oldAccount?.created}</Badge>
                            )}
                            {!oldAccount?.created && "-"}
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item style={{ alignItems: "center" }}>
                          <DataList.Label minWidth="88px">
                            Verificado
                          </DataList.Label>
                          <DataList.Value>
                            <Flex gap={"2"} align={"center"}>
                              <Badge
                                style={{ marginRight: 30 }}
                                color={
                                  oldAccount?.is_verified ? "green" : "red"
                                }
                              >
                                {oldAccount?.is_verified ? "Sim" : "Não"}
                              </Badge>
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">
                            Excluído
                          </DataList.Label>
                          <DataList.Value>
                            <Flex gap={"2"} align={"center"}>
                              <Badge
                                color={oldAccount?.excluded ? "green" : "red"}
                              >
                                {oldAccount?.excluded ? "Sim" : "Não"}
                              </Badge>
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>

                        <DataList.Item>
                          <DataList.Label minWidth="88px">
                            Bloqueado
                          </DataList.Label>
                          <DataList.Value>
                            <Flex gap={"2"} align={"center"}>
                              <Badge
                                color={oldAccount?.is_locked ? "green" : "red"}
                              >
                                {oldAccount?.is_locked ? "Sim" : "Não"}
                              </Badge>
                            </Flex>
                          </DataList.Value>
                        </DataList.Item>
                      </DataList.Root>
                    </Card>
                  )}
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid columns="2" gap="5" rows="repeat(1, auto)" width="auto">
                    {isLoading && (
                      <>
                        <Skeleton
                          height={"150px"}
                          width={"auto"}
                          style={{ borderRadius: 10 }}
                        />
                        <Skeleton
                          height={"150px"}
                          width={"auto"}
                          style={{ borderRadius: 10 }}
                        />
                        <Skeleton
                          height={"150px"}
                          width={"auto"}
                          style={{ borderRadius: 10 }}
                        />
                        <Skeleton
                          height={"150px"}
                          width={"auto"}
                          style={{ borderRadius: 10 }}
                        />
                        <Skeleton
                          height={"150px"}
                          width={"auto"}
                          style={{ borderRadius: 10 }}
                        />
                        <Skeleton
                          height={"150px"}
                          width={"auto"}
                          style={{ borderRadius: 10 }}
                        />
                        <Skeleton
                          height={"150px"}
                          width={"auto"}
                          style={{ borderRadius: 10 }}
                        />
                        <Skeleton
                          height={"150px"}
                          width={"auto"}
                          style={{ borderRadius: 10 }}
                        />
                      </>
                    )}
                    {!isLoading && oldAccount && (
                      <Card className={CardWrapper}>
                        <CardTitle>
                          <Text mt={"10px"} size={"1"} as="p">
                            {t("Liquid_Revenue")}
                          </Text>
                        </CardTitle>
                        <CardDescription>
                          <Text size={"7"} as="p" mt={"-20px"}>
                            {formatCurrency(
                              (oldAccount?.statistics.withdrawal_amount -
                                oldAccount?.statistics.deposit_amount +
                                oldAccount?.balance) *
                                -1
                            )}
                          </Text>
                        </CardDescription>
                      </Card>
                    )}

                    {!isLoading && oldAccount && (
                      <Card className={CardWrapper}>
                        <CardTitle>
                          <Text mt={"10px"} size={"1"} as="p">
                            {t("Deposit_Count")}
                          </Text>
                        </CardTitle>
                        <CardDescription>
                          <Text size={"7"} as="p" mt={"-20px"}>
                            {oldAccount?.statistics.deposit_count}
                          </Text>
                        </CardDescription>
                      </Card>
                    )}

                    {!isLoading && oldAccount && (
                      <Card className={CardWrapper}>
                        <CardTitle>
                          <Text mt={"10px"} size={"1"} as="p">
                            {t("Deposit_Amount")}
                          </Text>
                        </CardTitle>
                        <CardDescription>
                          <Text size={"7"} as="p" mt={"-20px"}>
                            {formatCurrency(
                              oldAccount?.statistics.deposit_amount
                            )}
                          </Text>
                        </CardDescription>
                      </Card>
                    )}

                    {!isLoading && oldAccount && (
                      <Card className={CardWrapper}>
                        <CardTitle>
                          <Text mt={"10px"} size={"1"} as="p">
                            {t("First_Deposit")}
                          </Text>
                        </CardTitle>
                        <CardDescription>
                          <Text size={"7"} as="p" mt={"-20px"}>
                            {oldAccount?.statistics.first_deposit_date}
                          </Text>
                        </CardDescription>
                      </Card>
                    )}

                    {!isLoading && oldAccount && (
                      <Card className={CardWrapper}>
                        <CardTitle>
                          <Text mt={"10px"} size={"1"} as="p">
                            {t("Last_Deposit")}
                          </Text>
                        </CardTitle>
                        <CardDescription>
                          <Text size={"7"} as="p" mt={"-20px"}>
                            {oldAccount?.statistics.last_deposit_date}
                          </Text>
                        </CardDescription>
                      </Card>
                    )}

                    {!isLoading && oldAccount && (
                      <Card className={CardWrapper}>
                        <CardTitle>
                          <Text mt={"10px"} size={"1"} as="p">
                            {t("Last_Deposit_Amount")}
                          </Text>
                        </CardTitle>
                        <CardDescription>
                          <Text size={"7"} as="p" mt={"-20px"}>
                            {formatCurrency(
                              oldAccount?.statistics.last_deposit_amount
                            )}
                          </Text>
                        </CardDescription>
                      </Card>
                    )}

                    {!isLoading && oldAccount && (
                      <Card className={CardWrapper}>
                        <CardTitle>
                          <Text mt={"10px"} size={"1"} as="p">
                            {t("Withdrawal_Amount")}
                          </Text>
                        </CardTitle>
                        <CardDescription>
                          <Text size={"7"} as="p" mt={"-20px"}>
                            {formatCurrency(
                              oldAccount?.statistics.withdrawal_amount
                            )}
                          </Text>
                        </CardDescription>
                      </Card>
                    )}

                    {!isLoading && oldAccount && (
                      <Card className={CardWrapper}>
                        <CardTitle>
                          <Text mt={"10px"} size={"1"} as="p">
                            {t("Withdrawal_Count")}
                          </Text>
                        </CardTitle>
                        <CardDescription>
                          <Text size={"7"} as="p" mt={"-20px"}>
                            {oldAccount?.statistics.withdrawal_count}
                          </Text>
                        </CardDescription>
                      </Card>
                    )}
                  </Grid>
                </div>
              </div>
            )}
          </Flex>
        </Theme>
      </Modal>
    </>
  );
};

export default OldAccount;
