import { createUseStyles } from 'react-jss';

const requestUsersStyles = createUseStyles({
    BackButtonStyle: {
        padding: '10px 10px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 0fr)',
        gap: '6px',
        cursor: 'pointer',
        backgroundColor: '#1D1D21',
        width: 'fit-content',
        borderRadius: 10,
        border:'1px solid #D9EDFE25'
    },
});

export default requestUsersStyles;