import { Box, Flex, Tabs, Text } from "@radix-ui/themes";
import { Bell } from "lucide-react";
import { useEffect, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { websocketService } from "src/infraestructure/services/websocket";
import useUserRole from "src/hooks/useUserRole";
import useNotification from "src/hooks/useNotification";
import { useNavigate } from "react-router";

export function NotificationsDropdown() {
  const role = useUserRole();
  const navigate = useNavigate();
  const { notifications, handleNotification } = useNotification();
  const [isConnected, setIsConnected] = useState(false);
  const user = window.localStorage.getItem("@App:user");
  const userData = JSON.parse(user || "{}");

  // Função responsável apenas pela conexão do WebSocket
  async function handleWebSocketConnection() {
    if (isConnected) {
      return;
    }

    try {
      if (!user || !userData.id) {
        console.log("❌ Dados do usuário não encontrados");
        return;
      }

      await websocketService.connect();
      await websocketService.openSession(userData.id);
      setIsConnected(true);
    } catch (error) {
      console.error("❌ Erro na conexão WebSocket:", error);
      setIsConnected(false);
    }
  }

  // Função responsável por buscar as notificações pendentes
  async function fetchPendingNotifications() {
    try {
      if (!userData.id || !isConnected) {
        console.log(
          "❌ Não é possível buscar notificações: usuário não identificado ou WebSocket desconectado"
        );
        return;
      }

      await websocketService.pendingSolicitations(userData.id);
    } catch (error) {
      console.error("❌ Erro ao buscar notificações pendentes:", error);
    }
  }

  // Função responsável por configurar o listener de notificações
  function setupNotificationListener() {
    const handleMessage = (data: any) => {
      if (
        data.message === "new_notification" ||
        data.message === "All pending solicitations"
      ) {
        handleNotification(data.data);
      }
    };

    websocketService.addListener(handleMessage);
    return () => {
      websocketService.removeListener(handleMessage);
    };
  }

  useEffect(() => {
    let cleanup: (() => void) | undefined;

    async function initializeWebSocket() {
      if (role === "ADMINISTRATOR" || role === "MODERATOR") {
        await handleWebSocketConnection();

        if (isConnected) {
          cleanup = setupNotificationListener();
          await fetchPendingNotifications();
        }
      }
    }

    initializeWebSocket();

    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [role, isConnected]);

  return (
    <>
      {/* <button
        onClick={() => {
          console.log(notifications);
        }}
      >
        iuhavwduyawvd
      </button> */}
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button
            style={{
              position: "relative",
              background: "none",
              border: "none",
              cursor: "pointer",
              padding: "8px",
            }}
          >
            <Bell size={20} color="#fff" />
            {notifications?.length > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "#6382fe",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                {notifications.length}
              </span>
            )}
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            style={{
              backgroundColor: "#1e2024",
              color: "#fff",
              borderRadius: "8px",
              padding: "16px",
              width: "320px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <Tabs.Root defaultValue="notifications">
              <Tabs.List>
                <Tabs.Trigger
                  value="notifications"
                  style={{ color: "#9db1ff", marginBottom: 10 }}
                >
                  Notificações ({notifications.length})
                </Tabs.Trigger>
              </Tabs.List>

              <Box py="4">
                <Tabs.Content value="notifications">
                  <Flex direction="column" gap="3">
                    {notifications.map((notification, index) => (
                      <Flex
                        key={index}
                        gap="3"
                        align="center"
                        className="notification-item"
                        style={{
                          padding: "8px",
                          borderRadius: "4px",
                          cursor: "pointer",
                          marginTop: 15,
                        }}
                        onClick={() => {
                          if (notification.type === "solicitation") {
                            navigate("/admin/solicitacoes");
                          }
                        }}
                      >
                        <style>
                          {`
                          .notification-item:hover {
                            background-color: #00000040;
                          }
                        `}
                        </style>
                        <Box>{<Bell size={20} color="#9db1ff" />}</Box>
                        <Box style={{ marginLeft: 20 }}>
                          <Text
                            as="div"
                            size="2"
                            weight="bold"
                            style={{ color: "#959292", marginBottom: 8 }}
                          >
                            {notification.requestor_id}
                          </Text>
                          <Text as="div" size="1">
                            Nova Solicitação
                          </Text>
                          <Text
                            as="div"
                            size="1"
                            weight="bold"
                            style={{
                              color: "#959292",
                              marginTop: 8,
                              fontSize: 12,
                            }}
                          >
                            {notification.created_at}
                          </Text>
                        </Box>
                      </Flex>
                    ))}
                  </Flex>
                </Tabs.Content>

                <Tabs.Content value="reminders">
                  <Text>Nenhum lembrete</Text>
                </Tabs.Content>
              </Box>
            </Tabs.Root>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
}
