import { useState, useCallback, useEffect } from 'react';

type TNotification = {
  created_at: string;
  justification: string;
  requestor_id: string;
  status: string;
  type: string;
  _id: { $oid: string };
};

const STORAGE_KEY = '@App:notifications';

export default function useNotification() {
  // Inicializa o estado com as notificações do localStorage
  const [notifications, setNotifications] = useState<TNotification[]>(() => {
    const savedNotifications = localStorage.getItem(STORAGE_KEY);
    return savedNotifications ? JSON.parse(savedNotifications) : [];
  });

  // Persiste as notificações no localStorage sempre que houver mudanças
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(notifications));
  }, [notifications]);

  const handleNotification = useCallback((newNotification: TNotification | TNotification[]) => {
    if (Array.isArray(newNotification)) {
      setNotifications(newNotification);
      return newNotification;
    } else {
      setNotifications(prev => {
        // Verifica se a notificação já existe
        const notificationExists = prev.some(
          notification => notification._id.$oid === newNotification._id.$oid
        );

        if (notificationExists) {
          return prev;
        }

        // Adiciona nova notificação no início do array
        return [newNotification, ...prev];
      });
      return [newNotification];
    }
  }, []);

  const clearNotifications = useCallback(() => {
    setNotifications([]);
    localStorage.removeItem(STORAGE_KEY);
  }, []);

  const removeNotification = useCallback((notificationId: string) => {
    setNotifications(prev => {
      const updated = prev.filter(notification => notification._id.$oid !== notificationId);
      return updated;
    });
  }, []);

  return {
    notifications,
    handleNotification,
    clearNotifications,
    removeNotification
  };
} 