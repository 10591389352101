import { ChevronLeft, LockKeyhole, User } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import MainLayout from "src/components/MainLayout";
import {
  Badge,
  Box,
  Button,
  Flex,
  Text,
  TextArea,
  TextField,
  Theme,
} from "@radix-ui/themes";
import requestUsersStyles from "src/styles/components/requestUsersStyles";
import TablePaginated from "src/components/TablePaginated";
import { useEffect, useState } from "react";
import { TRequestResponse } from "src/@types/admin";
import Requests from "src/infraestructure/services/api/Admin/Requests";
import toast from "react-hot-toast";
import { getColorFromStatus } from "src/utils";
import Modal from "react-modal";
import { Label } from "@radix-ui/react-label";
import { websocketService } from "src/infraestructure/services/websocket";

export default function RequestUsers() {
  const [requestsList, setRequestsList] = useState<TRequestResponse[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<TRequestResponse>(
    {} as TRequestResponse
  );
  const { BackButtonStyle } = requestUsersStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = window.localStorage.getItem("@App:user");
  const userData = JSON.parse(user || "{}");
  const customStyles = {
    modal: {
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.667)",
        zIndex: 998,
      },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        background: "#1e2024",
        minHeight: 800,
        maxHeight: 800,
        minWidth: 600,
        maxWidth: 600,
        display: "flex",
        borderRadius: 12,
        justifyContent: "center",
        border: "unset !important",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: "30px 50px",
      },
    },
    boxAvatar: {
      backgroundColor: "#696E77",
      padding: 30,
      borderRadius: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 15,
    },
  };

  async function handleRequests(page?: number) {
    const requestRequests = await Requests.get({
      page: page ? page : 1,
      limit: 20,
    });

    if (requestRequests.status === 200) {
      setTotalItems(requestRequests.pagination.total_solicitation);
      setRequestsList(requestRequests.data);
    }
  }
    // Função responsável por buscar as notificações pendentes
    async function fetchPendingNotifications() {
      try {
        if (!userData.id) {
          console.log(
            "❌ Não é possível buscar notificações: usuário não identificado"
          );
          return;
        }
  
        await websocketService.pendingSolicitations(userData.id);
      } catch (error) {
        console.error("❌ Erro ao buscar notificações pendentes:", error);
      }
    }

  async function handleUpdateStatus(id: string, type: string) {
    try {
      const updateStatus = await Requests.put(id, type);

      if (updateStatus.status === 200) {
        toast.success(t(updateStatus.message));
        handleRequests();
        setIsOpenModal(false);
        fetchPendingNotifications();
      }
    } catch (err) {}
  }

  useEffect(() => {
    handleRequests();
  }, []);

  return (
    <MainLayout>
      <Modal
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        style={customStyles.modal}
      >
        <Theme
          style={{
            width: "100%",
            minHeight: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Flex direction="column" justify="center" align="center" width="100%">
            <Box style={customStyles.boxAvatar}>
              {modalData.avatar_url ? (
                <img src={modalData.avatar_url} alt="Avatar" height={60} />
              ) : (
                <User size={30} />
              )}
            </Box>
            <Flex
              direction="column"
              justify="center"
              align="center"
              width="70%"
              gap="4"
            >
              <Text style={{ textAlign: "center", fontSize: 32 }}>
                {modalData.user_name}
              </Text>
              <Box
                style={{
                  width: "45%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 30,
                }}
              >
                <Text style={{ color: "#F1F7FEA8", fontSize: 14 }}>
                  Hora da solicitação:
                </Text>
                <Text style={{ color: "#F1F7FEA8", fontSize: 14 }}>
                  {" "}
                  {modalData.created_at && modalData.created_at.slice(11, 16)}
                </Text>
              </Box>
              <Box
                style={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 30,
                }}
              >
                <Box width="130px">
                  <Label style={{ fontSize: 14 }}>Horário Esperado</Label>
                  <TextField.Root
                    style={{ marginTop: 12, backgroundColor: "#00000040" }}
                    size="3"
                    value={modalData.start_time}
                    // disabled
                  >
                    <TextField.Slot />
                    <TextField.Slot>
                      <LockKeyhole height="16" width="16" />
                    </TextField.Slot>
                  </TextField.Root>
                </Box>
                <Box width="130px">
                  <Label style={{ fontSize: 14 }}>Horário de Entrada</Label>
                  <TextField.Root
                    style={{ marginTop: 12, backgroundColor: "#00000040" }}
                    size="3"
                    value={
                      modalData.created_at && modalData.created_at.slice(11, 16)
                    }
                    // disabled
                  >
                    <TextField.Slot />
                    <TextField.Slot>
                      <LockKeyhole height="16" width="16" />
                    </TextField.Slot>
                  </TextField.Root>
                </Box>
              </Box>
              <Box width={"80%"}>
                <Label style={{ fontSize: 18 }}>Observação</Label>
                <TextArea
                  placeholder="Digite sua solicitação aqui..."
                  size="3"
                  style={{
                    width: "100%",
                    minHeight: "100px",
                    marginBottom: 15,
                    marginTop: 15,
                    backgroundColor: "#00000040",
                  }}
                  variant="classic"
                  maxLength={150}
                  value={modalData.justification}
                />
              </Box>

              <Box
                style={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 30,
                }}
              >
                <Button
                  title="teste"
                  size="3"
                  style={{ cursor: "pointer" }}
                  disabled={modalData.status !== "pending"}
                  onClick={() =>
                    handleUpdateStatus(modalData.id.oid, "approved")
                  }
                >
                  Aprovar
                </Button>
                <Button
                  title="teste"
                  color="red"
                  size="3"
                  style={{ cursor: "pointer" }}
                  disabled={modalData.status !== "pending"}
                  onClick={() =>
                    handleUpdateStatus(modalData.id.oid, "refused")
                  }
                >
                  Recusar
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Theme>
      </Modal>
      <div
        className={BackButtonStyle}
        onClick={() => {
          navigate("/");
        }}
      >
        <ChevronLeft />
        <Text>{t("Voltar")}</Text>
      </div>

      <Box
        style={{
          background: "var(--color-panel)",
          borderRadius: 10,
          paddingBottom: 20,
          marginTop: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            padding: 30,
            background: "#222227",
            width: "100%",
            height: "150px",
            borderRadius: "10px 10px 0 0",
            marginBottom: 50,
          }}
        >
          <Flex direction={"column"} gap={"20px"}>
            <Text style={{ fontWeight: "bold", fontSize: 20 }}>
              Historico de Solicitações
            </Text>
            <Text style={{ fontWeight: "500", fontSize: 15 }}>
              Registro das últimas solicitações
            </Text>
          </Flex>
        </Box>
        <Box style={{ width: "90%" }}>
          <TablePaginated
            totalItems={totalItems}
            keys={[
              {
                name: "avatar_url",
                type: "image",
                imageSize: 50,
              },
              {
                name: "Usuário",
                type: "string",
                children: (result) => {
                  return (
                    <Box>
                      <p>{result.user_name}</p>
                      <p>{result.user_email}</p>
                    </Box>
                  );
                },
              },
              {
                name: "status",
                type: "string",
                children: (result) => {
                  return (
                    <Flex height={"100%"} align={"center"}>
                      <Badge color={getColorFromStatus(result.status)}>
                        {t(result.status)}
                      </Badge>
                    </Flex>
                  );
                },
              },
              {
                name: "created_at",
                type: "string",
                children: (result) => {
                  return (
                    <Flex height={"100%"} align={"center"}>
                      {result.created_at}
                    </Flex>
                  );
                },
              },
              {
                name: "actions",
                type: "string",
                children: (result) => {
                  return (
                    <Flex height={"100%"} align={"center"}>
                      <Button
                        title="teste"
                        color="indigo"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsOpenModal(true);
                          setModalData(result);
                        }}
                      >
                        Visualizar
                      </Button>
                    </Flex>
                  );
                },
              },
            ]}
            data={requestsList}
            perPage={20}
            onPageChange={(page: number) => {
              handleRequests(page);
            }}
          />
        </Box>
      </Box>
    </MainLayout>
  );
}
