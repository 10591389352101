export const MenuList = [
    {
        id: 1,
        name: "Clients",
        permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1'],
        submenus: [
            {
                id: 2,
                name: 'List',
                path: '/clients/list',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            },
            {
                id: 11,
                name: 'Withdrawal_Requests',
                path: '/withdrawal/requests',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            }
        ]
    },
    {
        id: 12,
        name: "Casino",
        permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1'],
        submenus: [
            {
                id: 13,
                name: 'Report_per_bet',
                path: '/bonus/report',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            },
            {
                id: 14,
                name: 'Live_Casino_Report',
                path: '/live-casino/report',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            }
        ]
    },
    {
        id: 15,
        name: "Sportbook",
        permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1'],
        submenus: [
            {
                id: 16,
                name: 'Bet_List',
                path: '/bet/list',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            },
            {
                id: 17,
                name: 'Bet_Report',
                path: '/bet/report',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            },
            {
                id: 19,
                name: 'Betting_History',
                path: '/betting/history',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            }
        ]
    },
    {
        id: 3,
        name: "Affiliates",
        permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1'],
        submenus: [
            {
                id: 4,
                name: 'List',
                path: '/affiliates/list',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            },
            {
                id: 5,
                name: 'Transactions',
                path: '/affiliates/transactions',
                permissions: ['ADMINISTRATOR']
            }
        ]
    },
    {
        id: 6,
        name: "Payments",
        permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1'],
        submenus: [
            {
                id: 7,
                name: 'List',
                path: '/admin/payments',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4', 'SUPPORT_LEVEL_2', 'SUPPORT_LEVEL_1']
            }
        ]
    },
    {
        id: 21,
        name: "KYC",
        permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4'],
        submenus: [
            {
                id: 27,
                name: 'List',
                path: '/kyc/list',
                permissions: ['ADMINISTRATOR', 'MODERATOR', 'SUPPORT_LEVEL_3', 'SUPPORT_LEVEL_4']
            }
        ]
    },
    {
        id: 8,
        name: "Admin",
        permissions: ['ADMINISTRATOR'],
        submenus: [
            {
                id: 9,
                name: 'Users',
                path: '/admin/users',
                permissions: ['ADMINISTRATOR']
            },
            {
                id: 10,
                name: 'Transactions Pending',
                path: '/admin/transactions/pending',
                permissions: ['ADMINISTRATOR',]
            },
            {
                id: 11,
                name: 'Settings',
                path: '/admin/config',
                permissions: ['ADMINISTRATOR']
            },
            {
                id: 18,
                name: 'Solicitações',
                path: '/admin/solicitacoes',
                permissions: ['ADMINISTRATOR']
            },
        ]
    },
    {
        id: 8,
        name: "Admin",
        permissions: ['SUPPORT_LEVEL_4'],
        submenus: [
            {
                id: 10,
                name: 'Transactions Pending',
                path: '/admin/transactions/pending',
                permissions: ['SUPPORT_LEVEL_4']
            }
        ]
    }
];