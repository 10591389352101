import { Badge, Box, Button, Flex, IconButton, Popover, Select, Spinner, TextField } from '@radix-ui/themes';
import { EditIcon } from 'lucide-react';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { TClientEditRequest } from 'src/@types/clients';
import Clients from 'src/infraestructure/services/api/Clients';

type EditUserDetailsProps = {
    type: string;
    userId: string
    defaultValue?: string;
    onFinish: (data: any, type: string) => void;
}

type EditUserDetailsForm = {
    value: string;
}

const EditUserDetails = ({ type, userId, defaultValue, onFinish }: EditUserDetailsProps) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<EditUserDetailsForm>()
    const onSubmit: SubmitHandler<EditUserDetailsForm> = async (data) => {
        console.log(data, type);
        
        setLoading(true);
        let dataSend: TClientEditRequest = {};
        if (type === "cpf") {
            dataSend['personalId'] = data.value;
        }
        if (type === "gender") {
            dataSend['gender'] = data.value.toLowerCase();
        }
        if (type === "phone") {
            dataSend['phoneNumber'] = data.value;
        }
        if(type === "email") {
            dataSend['email'] = data.value;
        }
        if(type === "date") {
            dataSend['birthDate'] = data.value;
        }
        if(type === "isVerified") {
            dataSend['isVerified'] = data.value === "true" ? true : false;
        }
        try{

            const request = await Clients.edit(userId, dataSend);
            if(request && request.data) {
                onFinish(request.data, type);
                toast.success(`Dados alterados com sucesso!`);
            }
            setLoading(false);
            setOpen(false);
        }catch(err){
            setLoading(false);
            setOpen(false);
        }
    }


    return (
        <Popover.Root open={open} onOpenChange={setOpen}>
            <Popover.Trigger>
                <IconButton radius='full'>
                    <EditIcon size={"18"} />
                </IconButton>
            </Popover.Trigger>
            <Popover.Content width="360px">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex gap="3">
                        <Box flexGrow="1">

                            {(type === "cpf" || type === "name" || type === "phone" || type === "email" || type === "date") && 
                            <TextField.Root {...register('value', {
                                required: (type === "name") ? true : false
                            })}
                            defaultValue={defaultValue} 
                            placeholder={`Digite o ${type}`} type={type === 'date' ? 'date' : "text"} />}

                            {(type === "name" || type === "phone" || type === "email") && errors.value && <div style={{ color: 'red' }}>{t('Value_Required')}</div>}

                            {type === "isVerified" &&
                                <Controller
                                    control={control}
                                    name="value"
                                    defaultValue={defaultValue}
                                    rules={{
                                        required: type === "isVerified" ? true : false
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                        <Select.Root value={value} onValueChange={onChange}>
                                            <Select.Trigger />
                                            <Select.Content>
                                                <Select.Group>
                                                    <Select.Label>{t('Select_Is_Verifield')}</Select.Label>
                                                    <Select.Item value={"true"}>
                                                        <Badge color="green">
                                                            Sim
                                                        </Badge>
                                                    </Select.Item>
                                                    <Select.Item value={"false"}>
                                                        <Badge color="red">
                                                            Não
                                                        </Badge>
                                                    </Select.Item>
                                                </Select.Group>
                                            </Select.Content>
                                        </Select.Root>
                                    )}
                                />}

                            {type === "gender" &&
                                <Controller
                                    control={control}
                                    name="value"
                                    defaultValue={defaultValue}
                                    rules={{
                                        required: type === "gender" ? true : false
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref }, formState, fieldState }) => (
                                        <Select.Root value={value} onValueChange={onChange}>
                                            <Select.Trigger />
                                            <Select.Content>
                                                <Select.Group>
                                                    <Select.Label>{t('Select_Gender')}</Select.Label>
                                                    <Select.Item value={'Male'}>{t('Male')}</Select.Item>
                                                    <Select.Item value={'Female'}>{t('Female')}</Select.Item>
                                                </Select.Group>
                                            </Select.Content>
                                        </Select.Root>
                                    )}
                                />}

                            {type === "gender" && errors.value && <div style={{ color: 'red' }}>{t('Value_Required')}</div>}

                            <Flex gap="3" mt="3" justify="between">
                                {!loading && <Button type='submit' size="1">{t('Save')}</Button>}
                                {loading && <Button size="1"><Spinner size={"1"} /></Button>}
                            </Flex>
                        </Box>
                    </Flex>
                </form>
            </Popover.Content>
        </Popover.Root>
    );
}

export default EditUserDetails;