import { TLoginFormRequest, TUserById, TVerifyTopop } from "src/@types/auth";
import Gateway from "../Gateway";

const route = "/v1/auth/login";
const routeByUser = "/v1/users";
const routeGetUser = "/v1/users/me";
const routeCheckTotop = "/v1/users/check-totp-time";
const routeVerifyTotop = "/v1/auth/verify-totp-code";

const save = async (data: TLoginFormRequest) => Gateway({
  url: route, method: 'POST', data: {
    email: data.email,
    password: data.password
  }, filters: undefined, id: undefined, lastArg: undefined, type: undefined, hasTotop: data.totp_code
});
const getById = async (data: TUserById) => Gateway({
  url: routeByUser,
  id: data.userId
});

const getMe = async () => Gateway({
  url: routeGetUser
});

export const isLogged = (): boolean => localStorage.getItem("@App:token") !== null;

const verifyTotop = async (data: TVerifyTopop) => Gateway({ url: routeVerifyTotop, method: 'POST', data, filters: undefined, id: undefined, lastArg: undefined, type: undefined, hasTotop: data.totopToken });

const checkTotop = async () => Gateway({
  url: routeCheckTotop
});

const getToken = () => {
  if (isLogged()) {
    return localStorage.getItem("@App:token");
  }
  return undefined;
  //   throw new (ClientError as any)("Usuário não está logado.");
};

export default {
  save,
  getToken,
  getById,
  verifyTotop,
  checkTotop,
  getMe
  //   get,
  //   logout
};